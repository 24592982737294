<template>
  <div id="studentExamResult">
    <!-- breadCrumb -->
    <v-breadcrumbs :items="breadcrumbList" divider="/">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item :to="item.href" :disabled="item.disabled">
          {{ item.text }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <!-- breadCrumb -->
    <!-- <v-row>
      <v-col cols="10">
        <div class="title">
          <h1>{{ $t(HomeWorkData.title) }}</h1>
        </div>
        <div class="title">
          <h2 class="main-color">
            {{ HomeWorkData.subject }}
          </h2>
        </div>
      </v-col>
    </v-row> -->
    <!-- <v-row>
      <v-col cols="12" md="8" offset-md="2" class="info-list">
        <ul class="inline">
          <li>
            <strong> {{ $t("Grade") }}:</strong> {{ HomeWorkData.grade }}
          </li>
          <li>
            <strong> {{ $t("Assignment Mark") }}:</strong> {{ HomeWorkData.mark }}
          </li>
        </ul>
      </v-col>

      <v-col cols="12" md="12" class="hr-div">
        <hr />
      </v-col>
    </v-row> -->
    <v-row>
      <v-col cols="12" md="12" class="table-grid">
        <v-data-table
          :options.sync="options"
          :loading="loading"
          loading-text="Loading... Please wait"
          :headers="headers"
          :items="homeWorkMarks"
          item-key="id"
          :show-select="false"
          class="elevation-1 level1 templates"
          text="left"
          hide-default-footer
        >
        <template v-slot:top>
          <v-row class="headerTable-div">
            <v-col md="3" sm="12">
              <h3 class="custom-header main-color">{{ $t(HomeWorkData.title) }}</h3>
            </v-col>
            <v-col md="2" sm="12">
              <h3 class="custom-header main-color">{{ HomeWorkData.subject }}</h3>
        
              
            </v-col>
            <v-col md="2" sm="12">
              <h3 class="custom-header main-color"> {{ $t("Grade") }}: {{ HomeWorkData.grade }}</h3>
            
            </v-col>
            <v-col md="2" sm="12">
              <h3 class="custom-header main-color"> {{ $t("Assignment Mark") }}: {{ HomeWorkData.mark }}</h3>
            
            </v-col>
        
            <v-col md="2" sm="12" class="text-right">
              <!-- ==================================================filters================================================== -->
              <span title="Filter">
                <v-menu v-model="filterMenu" offset-y :close-on-content-click="false" :nudge-width="300" offset-x>
                  <template v-slot:activator="{ on }">
                    <i v-on="on" class="fas fa-filter main-color fa-lg" :title="$t('Filter')"></i>
                  </template>
              
                  <v-list>
                    <v-list-item class="select">
                      <v-autocomplete v-model="filter.types" :label="$t('Type')" :items="types" @change="hideLabel = true"
                        item-text="name" item-value="id" solo></v-autocomplete>
                    </v-list-item>
              
                    <v-card-actions> </v-card-actions>
                    <v-list-item class="select">
                      <v-autocomplete v-model="filter.classes" :label="$t('Classes')" :items="classes" @change="hideLabel = true" item-text="name"
                        item-value="id" multiple solo></v-autocomplete>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </span>
            </v-col>
            <v-col cols="12" md="12" class="hr-div">
              <hr />
            </v-col>
          </v-row>
        </template>
          <template v-slot:item.actions="{ item }">
            <router-link
              :to="'/viewHomeWork/' + homeWorkId + '/' + item.student_id"
              v-if="item.is_submission_time"
            >
              <v-icon :title="$t('view student answers')">assignment</v-icon>
            </router-link>
            <v-icon v-else disabled>assignment</v-icon>
          </template>

          <template v-slot:item.student_name="{ item }">
            <StudentHoverMenu
              :studentName="item.student_name"
              :studentId="item.student_id"
              :userId="item.user_id"
              v-if="item.student_id"
            ></StudentHoverMenu>
            <span v-else>{{ item.student_name }}</span>
          </template>
        </v-data-table>
        <!-- ======================================== Pagination ======================================  -->
        <div class="text-center pt-2">
          <v-pagination
            v-if="length > 0 && totalItems > 0"
            v-model="page"
            :length="length"
            :circle="circle"
            :page="page"
            :total-visible="totalVisible"
            next-icon="mdi-menu-right"
            prev-icon="mdi-menu-left"
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
// import ACL from "../../acl";
import StudentHoverMenu from "@/components/Students/StudentHoverMenu";

export default {
  name: "StudentsHomeWorkResults",
  components: { StudentHoverMenu },
  data() {
    return {
      HomeWorkData: {},
      filterMenu: false,
      headers: [
        {
          text: this.$i18n.t("Student Code"),
          value: "student_code",
          sortable: false,
        },
        { text: this.$i18n.t("Name"), value: `student_name`, sortable: false },
        { text: this.$i18n.t('Class'), value: "class_name", sortable: false},
        {
          text: this.$i18n.t("Marks"),
          value: "mark",
          width: "100px",
          sortable: false,
        },
        {
          text: this.$i18n.t("Submission Time"),
          value: "submission_time",
          sortable: false,
        },
        { text: this.$i18n.t("Actions"), value: "actions", sortable: false },
      ],
      homeWorkMarks: [],
      loading: false,
      options: {
        itemsPerPage: 100,
      },
      circle: true,
      page: 1,
      length: "",
      totalVisible: "",
      totalItems: "",
      types: [
        { id: 'all', name: this.$i18n.t("All") },
        {id : 'submitted', name : this.$i18n.t("Submitted")},
        { id: 'notsubmitted', name: this.$i18n.t("Not Submitted")}
      ],
      classes: [],
      filter:{

        types: "",
        classes:[]
      },
      homeWorkId: "",
    };
  },
  watch: {
    "$route.query": {
      handler() {
        if (this.$route.query.page !== undefined) {
          this.page = Number(this.$route.query.page);
          this.getDataFromApi(this.$route.query.page);
        } else {
          this.getDataFromApi(1);
        }
      },
      deep: true,
    },
    page: function() {
      this.getDataFromApi(this.page);
    },
    filter: {
      handler() {
        this.page = 1;
        this.getDataFromApi(this.page);
      },
      deep: true,
    },
  },
  methods: {
    getHomeWorkData() {
      axios
        .get(this.getApiUrl + "/homework/show/" + this.homeWorkId, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.HomeWorkData = response.data.data;
        });
    },
    getDataFromApi(page = null) {
      if (page == null) {
        page = this.page;
      }
      axios
        .get(
          this.getApiUrl +
            "/homework/studentsAssignmentresults/" +
            this.homeWorkId +
            "?page=" +
            page,
          {
            params: {
              filter: this.filter,
            },
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          // console.log(response.data.data.students.last_page);
          this.setPaginationParameters(response);
          this.totalItems = response.data.data.students.total;
          this.homeWorkMarks = response.data.data.students.data;
          this.classes = response.data.data.classes;
          this.loading = false;
          
        });
    },

    setPaginationParameters(response) {
      this.length = response.data.data.students.last_page;
      if (this.length > 5) this.totalVisible = 5;
    },
  },
  mounted() {
    this.homeWorkId = this.$router.currentRoute.params.homeWorkId;
    this.getHomeWorkData();
    this.getDataFromApi();
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_forms.scss";
.title h2 {
  margin-bottom: 0 !important;
  font-size: 1.5rem;
}
.info-list {
  padding-top: 0;
  padding-bottom: 0;
  li {
    display: inline-block;
    width: 33%;
    font-size: 13px;
    padding-right: 2px;
  }
}

@media (max-width: 600px) {
  .info-list {
    li {
      font-size: 10px;
    }
  }
}

.icons-div,
.hr-div {
  padding-top: 0;
  padding-bottom: 0;

  hr {
    margin-bottom: 0 !important;
    margin-top: 0.5rem !important;
  }
}

.table-grid {
  padding-top: 0 !important;
}

button.ui-btn.submit:disabled {
  cursor: not-allowed;
}
</style>
